<style lang="less" scoped>
/deep/ .ant-form-item-label {
  width: 95px;
  text-align: left;

}
/deep/ .ant-upload {
  line-height: 100%;
}
</style>
<template>
  <a-card :bordered="false" style="width: 100%">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">
          <a-col :md="6" :sm="24">
            <a-form-item label="司机姓名">
              <a-input v-model="queryParam.name" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="电话">
              <a-input v-model="queryParam.mobile" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="使用状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="-1">全部</a-select-option>
                <a-select-option value="1">正常</a-select-option>
                <a-select-option value="0">禁用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="(!advanced && 6) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd()" v-action:but_driver_add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 'max-content', y: scrollHeight }"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-action:but_driver_edit>编辑&ensp;|&ensp;</a>
          <a @click="updateStatus(record)" v-action:but_driver_disable>{{ record.status == 1 ? '禁用' : '启用' }} &ensp;|&ensp;</a>
          <!--如果需要权限 v-action:roleBD -->
          <a @click="bindVehicle(record)" v-action:but_driver_bind>绑定车辆</a>
        </template>
      </span>
    </s-table>

    <a-modal
      key="addDriver"
      title="新增/编辑"
      :width="700"
      :visible="visible_add"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="addDriverHandleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div>
        <a-form @submit="handleSubmit" :form="form">
          <table style="width: 100%">
            <tr>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="司机姓名"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
                >
                  <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入名称' }] }]" name="name" />
                </a-form-item>
              </td>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="电话"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
                >
                  <a-input
                    v-decorator="['mobile', { rules: [{ required: true, message: '请输入电话' },{validator:phoneCheck}] }]"
                    name="mobile"
                  />
                </a-form-item>
              </td>
            </tr>

            <tr>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="身份证号"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
                >
                  <a-textarea
                    v-decorator="['identityCard', { rules: [{ required: false, message: '请输入身份证' }] }]"
                    name="identityCard"
                  />
                </a-form-item>
              </td>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="身份证有效期"
                  :labelCol="{ lg: { span: 9 }, sm: { span: 9 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 13 } }"
                >
                  <a-input
                    v-decorator="['idValidityDate', { rules: [{ required: false, message: '请输入有效期' }] }]"
                    name="idValidityDate"
                  />
                </a-form-item>
              </td>
            </tr>

            <tr>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="驾驶证号"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
                >
                  <a-input
                    v-decorator="['dirverLicense', { rules: [{ required: false, message: '请输入号码' }] }]"
                    name="dirverLicense"
                  />
                </a-form-item>
              </td>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="驾驶证有效期"
                  :labelCol="{ lg: { span: 9 }, sm: { span: 9 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 13 } }"
                >
                  <a-input
                    v-decorator="['driverValidityDate', { rules: [{ required: false, message: '请输入' }] }]"
                    name="driverValidityDate"
                  />
                </a-form-item>
              </td>
            </tr>

            <tr>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="准驾车型"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
                >
                  <a-input
                    v-decorator="[
                      'quasiDriving',
                      { rules: [{ required: false, message: '请输入准驾例如C、C1D、A1、B2' }] },
                    ]"
                    name="quasiDriving"
                  />
                </a-form-item>
              </td>
            </tr>


            <tr>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="身份证照片"
                  :labelCol="{ lg: { span: 8 }, sm: { span: 8 } }"
                  :wrapperCol="{ lg: { span: 14 }, sm: { span: 14 } }"
                >
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest"
                  >
                    <img id="identityCardPic1" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </a-form-item>
              </td>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="驾驶证照片"
                  :labelCol="{ lg: { span: 8 }, sm: { span: 8 } }"
                  :wrapperCol="{ lg: { span: 14 }, sm: { span: 14 } }"
                >
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest2"
                  >
                    <img id="dirverLicensePic1" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </a-form-item>
              </td>
            </tr>
          </table>

          <a-form-item :wrapperCol="{ span: 24 }" class="form-submit">
            <a-button style="margin-right: 8px" @click="addDriverHandleCancel">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <a-modal
      key="bindVehicle"
      title="绑定车辆"
      :width="500"
      :visible="visible_add2"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="bindHandleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: '220px' }"
    >
      <div>
        <a-form @submit="handleSubmit2" :form="form2">
          <a-form-item
            label="选择车辆"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-select
              show-search
              placeholder="请选择"
              style="width: 358px"
              :filter-option="filterOption"
              @change="handleChange"
              v-decorator="['vehicleId']"
            >
              <a-select-option v-for="(item, index) in optionsVehicle" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :wrapperCol="{ span: 24 }" class="form-submit" style="margin-top:80px">
            <a-button style="margin-right: 8px" @click="bindHandleCancel">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { findPageDriver, updateDriver, saveDriver, deleteDriver, bindVehicle, findListVehicle } from '@/api/manage'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { put } from '@/utils/upload'
import AuthImg from '@/components/AuthImg/AuthImg'

export default {
  name: 'TableList',
  components: {
    STable,
    AuthImg,
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      treeData: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '姓名',
          width: 150,
          key: 'name',
          dataIndex: 'name',
          align: 'center',
          fixed: 'left',
        },
        {
          title: '电话',
          width: 150,
          key: 'mobile',
          dataIndex: 'mobile',
          align: 'center',
        },
        {
          title: '身份证号',
          width: 220,
          key: 'identityCard',
          dataIndex: 'identityCard',
          align: 'center',
        },
        {
          title: '驾驶证',
          width: 150,
          key: 'dirverLicense',
          dataIndex: 'dirverLicense',
          align: 'center',
        },
        {
          title: '驾驶证有效期',
          width: 150,
          key: 'driverValidityDate',
          dataIndex: 'driverValidityDate',
          align: 'center',
        },
        {
          title: '准驾车型',
          width: 150,
          key: 'quasiDriving',
          dataIndex: 'quasiDriving',
          align: 'center',
        },
        {
          title: '开户行',
          width: 150,
          key: 'depositBank',
          dataIndex: 'depositBank',
          align: 'center',
        },
        {
          title: '开户名',
          width: 150,
          key: 'accountName',
          dataIndex: 'accountName',
          align: 'center',
        },
        {
          title: '银行卡号',
          width: 220,
          key: 'accessNo',
          dataIndex: 'accessNo',
          align: 'center',
        },
        {
          title: '结算方式',
          width: 150,
          key: 'settlementType',
          dataIndex: 'settlementType',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '现付'
            } else {
              ret = '月结'
            }
            return ret
          },
        },
        {
          title: '状态',
          width: 150,
          key: 'status',
          dataIndex: 'status',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '正常'
            } else {
              ret = '禁用'
            }
            return ret
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '200px',
          fixed: 'right',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        param = Object.assign(parameter, this.queryParam)
        return findPageDriver(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      visible_add: false,
      visible_add2: false,
      form: this.$form.createForm(this),
      form2: this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      addFlag: true, //true新增false编辑
      expandedKeys: [], //已绑定的keys
      submitKeys: [], //提交保存的key,包括半选中和选中
      updateVo: {},
      thisVehicle: {},
      thisDriver:{},
      optionsVehicle: [],
      scrollHeight: 0,
    }
  },
  created() {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 400
  },
  filters: {},
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    handleEdit(record) {
      let that = this
      this.visible_add = true
      this.tabObj = record
      this.addFlag = false
      this.$nextTick(() => {
        this.form.setFieldsValue({
          name: record.name,
          mobile: record.mobile,
          contact: record.identityCard,
          idValidityDate: record.idValidityDate,
          dirverLicense: record.dirverLicense,
          driverValidityDate: record.driverValidityDate,
          quasiDriving: record.quasiDriving,
          // certificate: record.certificate,
          // depositBank: record.depositBank,
          // accountName: record.accountName,
          // accessNo: record.accessNo,
          // settlementType: record.settlementType+""
        })
        that.getimgblob('/service_provider/upload/getPic?fileName=' + record.identityCardPic1, 'identityCardPic1')
        that.getimgblob('/service_provider/upload/getPic?fileName=' + record.dirverLicensePic1, 'dirverLicensePic1')
      })
    },
    handleDel(record) {},
    updateStatus(record) {
      let targetstatus = 1
      if (record.status == 1) {
        targetstatus = 0
      }
      updateDriver({ id: record.id, status: targetstatus })
        .then((res) => {
          //提交成功
          this.$message.success('更新成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('更新失败')
        })
    },
    handleOk() {},

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    addDriverHandleCancel() {
      this.visible_add = false
    },
    handleAdd() {
      this.visible_add = true
      this.tabObj = {}
      this.updateVo = {}
      this.addFlag = true
    },
    handleChange2(value) {
      this.updateVo.settlementType = value
    },
    handleSubmit(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          if (that.addFlag) {
            if (that.updateVo.identityCardPic1 != null) {
              values.identityCardPic1 = that.updateVo.identityCardPic1
            }
            if (that.updateVo.dirverLicensePic1 != null) {
              values.dirverLicensePic1 = that.updateVo.dirverLicensePic1
            }

            saveDriver(values)
              .then((res) => {
                //提交成功
                this.$message.success('保存成功')
                that.visible_add = false
                this.$refs.table.refresh()
              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          } else {
            //修改角色
            values.id = this.tabObj.id
            if (that.updateVo.identityCardPic1 != null) {
              values.identityCardPic1 = that.updateVo.identityCardPic1
            }
            if (that.updateVo.dirverLicensePic1 != null) {
              values.dirverLicensePic1 = that.updateVo.dirverLicensePic1
            }
            updateDriver(values)
              .then((res) => {
                //提交成功
                this.$message.success('保存成功')
                that.visible_add = false
                this.$refs.table.refresh()
              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          }
        }
      })
    },
    iscandel(record) {
      if (record.type == 1) {
        return false
      } else {
        return true
      }
    },
    customRequest2(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          // this.loading = false
          // console.info("-----")
          let fileName = result.name
          this.updateVo.dirverLicensePic1 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'dirverLicensePic1')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          // this.loading = false
          // console.info("-----")
          let fileName = result.name
          this.updateVo.identityCardPic1 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'identityCardPic1')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    async getimgblob(url, id) {
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.responseType = 'blob'
      request.open('get', url, true)
      // 携带请求头
      request.setRequestHeader('token', token)
      request.onreadystatechange = (e) => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          document.getElementById(id).src = URL.createObjectURL(request.response)
        }
      }
      request.send(null)
    },
    bindVehicle(record) {
      let that = this
      this.visible_add2 = true
      this.thisDriver.id = record.id
      this.$nextTick(() => {
        findListVehicle({ status: 1 }).then((res) => {
          if (res != null && res.result != null) {
            that.optionsVehicle = []
            for (let i in res.result) {
              that.optionsVehicle.push({ value: res.result[i].id + '', label: res.result[i].vno })
            }
          }
        })
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChange(value) {
      this.thisVehicle.id = value
    },
    bindHandleCancel() {
      this.visible_add2 = false
    },
    handleSubmit2(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          values.vehicleId = that.thisVehicle.id;
          values.driverId = that.thisDriver.id;
          bindVehicle(values)
            .then((res) => {
              //提交成功
              this.$message.success('保存成功')
              that.visible_add2 = false
              this.$refs.table.refresh()
            })
            .catch((e) => {
              this.$message.error('保存失败')
            })
        }
      })
    },
    phoneCheck (rule, value, callback) {
      let flag = true
      if(value.length!=11){
        flag = false
        callback(new Error('请输入正确手机格式'))
      }
      if(flag==true){
        const reg =
          /^(\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$/;
        if (!reg.test(value)) {
          flag = false
          callback(new Error('请输入正确手机格式'))
        }
      }
      if(flag==true){
        callback()
      }

    },
  },
  computed: {
    headers() {
      let token = Vue.ls.get(ACCESS_TOKEN)
      return {
        token: token,
      }
    },
  },
}
</script>
<style lang="less" scoped>
.table-operator {
  margin-bottom: 10px;
}
/deep/ .ant-form-item-control-wrapper{
  width: 70%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
}
</style>